<template>
  <div class="w-full">
    <div
      class="relative hidden h-field cursor-pointer lg:flex lg:flex-col"
      tabindex="0"
      @blur="open = false"
    >
      <div
        class="flex h-full items-center justify-between border"
        :class="{
          open: open,
          'border-red-500 text-red-300': hasErrors,
          background,
          'order-2': isAbove,
          'order-1': !isAbove,
        }"
        data-cy="toggle"
        @click="toggle"
      >
        <span
          class="px-4 text-g7-blue"
          v-html="
            !modelValue.label && placeholder ? placeholder : modelValue.label
          "
        />
        <div
          :class="{ 'border-red-500': hasErrors }"
          class="flex h-full items-center justify-center border-l px-1"
        >
          <div class="w-4 sm:w-6 lg:w-8">
            <IconsUp
              class="ml-1 w-2 -rotate-180 fill-g7-blue transition sm:w-4 lg:w-6"
              :class="{ 'rotate-0': open }"
            />
          </div>
        </div>
      </div>
      <div :class="{ 'order-1': isAbove, 'order-2': !isAbove }">
        <div
          ref="dropdownTarget"
          class="absolute z-50 max-h-80 w-full overflow-auto border-x border-b bg-white shadow-lg transition duration-100"
          :class="{
            'invisible opacity-0': !open,
            '-translate-y-full': isAbove,
          }"
          :data-cy="dataCy"
        >
          <div
            v-for="(option, i) of options"
            :key="i"
            class="flex items-center text-g7-blue hover:bg-g7-blue hover:text-white"
            @click="update(option)"
          >
            <span class="py-2 pl-4" v-html="option.label" />
          </div>
        </div>
        <input type="hidden" :name="name" :value="modelValue.value" />
      </div>
    </div>
    <div class="relative flex h-field lg:hidden">
      <select
        :value="modelValue.value"
        :class="{ '!border-red-500': hasErrors }"
        class="w-full appearance-none border bg-white pl-3 pr-12 text-g7-blue focus:outline-none focus:ring focus:ring-g7-blue"
        @change="mobileUpdate(($event.target as HTMLInputElement).value)"
      >
        <option v-for="(option, i) of options" :key="i" :value="option.value">
          <span v-html="option.label" />
        </option>
      </select>
      <div
        :class="{ '!border-red-500': hasErrors }"
        class="pointer-events-none absolute right-0 flex h-full items-center justify-between border-l px-3 py-2"
      >
        <IconsUp class="w-4 -rotate-180 fill-g7-blue" />
      </div>
    </div>
    <ErrorMessage v-if="hasErrors" :message="errors.join(',')" />
  </div>
</template>

<script lang="ts" setup>
import type { Ref } from "vue";
import { useField } from "vee-validate";
import type { LabelValue } from "@/types/form";

interface Props {
  modelValue: LabelValue;
  options: Array<LabelValue>;
  validation?: string | object;
  name?: string;
  disabled?: boolean;
  dataCy?: string;
  placeholder?: string;
}

const props = withDefaults(defineProps<Props>(), {
  validation: "",
  name: "",
  disabled: false,
  dataCy: "",
  placeholder: "",
});
const isAbove = ref(false);
const dropdownTarget = ref<HTMLDivElement>();
const background = computed(() => {
  return props.disabled ? "bg-[#fafafa]" : "bg-white";
});
const { errors, meta } = useField(props.name, props.validation, {
  syncVModel: true,
});
const hasErrors = computed(() => errors.value.length > 0 && meta.touched);

const emit = defineEmits<{
  (e: "update:modelValue", value: LabelValue): void;
}>();
const open: Ref<boolean> = ref(false);

function update(option: LabelValue) {
  open.value = false;
  if (option.value === "-") {
    return;
  }
  emit("update:modelValue", option);
}

function mobileUpdate(value: string) {
  update(props.options.filter((lv) => lv.value === value)[0]);
}

watch(open, async () => {
  await nextTick();
  isAbove.value = isBoundingBottom(dropdownTarget.value);
});
function toggle() {
  if (!props.disabled) {
    open.value = !open.value;
  }
}
</script>
